/// <reference path='./typing.d.ts' />
//// <reference path='./pages/_index.ts' />

class Common {
	supportNav() {
		$('.js-support-toggle').on('click', ()=>{
			$('.support-list').slideToggle();

			return false;
		});
	}

	spGlobalNav() {
		let $nav = $('.global-nav');

		$(window).on('load', ()=> {
			$nav.addClass('loaded');
		});

		$nav.on('transitionend webkitTransitionEnd', ()=> {
			setTimeout(function(){
				$nav.removeClass('transitioning');	
			}, 400)
			
		});

		$('.js-global-menu-toggle').on('click', ()=>{
			$nav.toggleClass('active transitioning');
		});
	}

	toTop() {
		$('.js-to-top').on('click', ()=> {
			$('html, body').animate({
				scrollTop: 0
			}, {
				duration: 400
			});

			return false;
		})
	}


	protectImg() {
		$('.post-entry img').each(function(){
			if($(this).closest('.js-protect').length > 0) {
				return false;
			}

			$(this).wrap('<span class="js-protect"></span>');
		});

		$('.js-protect').aemProtectImage();
	}

	init() {
		$(()=> {
			this.supportNav();
			this.spGlobalNav();
			this.protectImg();
			this.toTop();
		})
	}
}

class Gallery {
	setupModal() {
		// $('.gallery-list__link').magnificPopup({
		// 	type:'inline'
		// });

		$('.gallery-list').magnificPopup({
			delegate: '.gallery-list__link',
			type: 'inline',
			gallery: {
				enabled: true
			}
		});
	}

	init() {
		if($('.gallery-list').length === 0) {
			return;
		}


		$(()=> {
			this.setupModal();
		});
	}
}


class Top {
	$topMenus: any;
	$topMenusText: any;

	topMenu() {
		let that = this

		let getTarget = (elem)=> {
			if(!elem.className) {
				return $;
			}

			let classNames = elem.className.match(/icon-menu__link--[^\s]+/);

			if(!classNames) {
				return $;
			}

			let className: string = classNames[0];
			let menuType = className.replace('icon-menu__link--', '');
			let $targetMenuText = that.$topMenusText.find('.top-members-menu__item--' + menuType);

			return $targetMenuText;
		}

		that.$topMenus.find('.icon-menu__item a').each(function(){
			let $targetMenuText = getTarget(this);
			$(this).data('targetMenuText', $targetMenuText);

			$targetMenuText.css('top', $(this).position().top);
		});


		that.$topMenus.find('.icon-menu__item a').on('mouseenter touchstart', function(){
			let $targetMenuText = $(this).data('targetMenuText')
			$targetMenuText.addClass('active');
		}).on('mouseleave touchend', function(){
			let $targetMenuText = $(this).data('targetMenuText')
			$targetMenuText.removeClass('active');
		});
	}

	initSlider() {
		$('.js-slider').bxSlider({
			controls: false
		});
	}

	init() {
		this.$topMenus = $('.top-members__menu .icon-menu');
		this.$topMenusText = $('.top-members-menu__text');

		if(this.$topMenus.length == 0 || this.$topMenusText.length == 0) {
			return;
		}

		$(()=> {
			if(this.$topMenus.length === 0) {
				return;
			}

			this.topMenu();
			this.initSlider();
		})
	}
}

let common = new Common();
common.init();

let topPage = new Top();
topPage.init();

let gallery = new Gallery();
gallery.init();

// $(function() {
// 	console.log("sd");
// });

